export const customizationInit = {
  color: null,
  model: null,
  switchType: null,
  layout: null,
  questions: {},
  userState: null,
};
export const questionBank = {
  'The letters "ty" in the word "city"... Which hand(s) did you use?': {
    "Left for T, right for Y": 1,
    "Left hand for both": -1,
    "Right hand for both": -1,
    "Anything else": -1,
  },
  'The letters "gh" in the word "high"... Which hand(s) did you use?': {
    "Left for G, right for H": 1,
    "Left hand for both": -1,
    "Right hand for both": -1,
    "Anything else": -1,
  },
  'The letters "b" and "n" in the word "bun"... Which hand(s) did you use?': {
    "Left for B, right for N": 1,
    "Left hand for both": -1,
    "Right hand for both": -1,
    "Anything else": -1,
  },
  "How often do you use f1, f2... f12?": {
    "Not so often": 1,
    "Very often": -1,
  },
};
