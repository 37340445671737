
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAnaPt-_HkggLAv5mp5O5vVkGzbT4HjSgk",
  authDomain: "davinci-web.firebaseapp.com",
  projectId: "davinci-web",
  storageBucket: "davinci-web.appspot.com",
  messagingSenderId: "19489666162",
  appId: "1:19489666162:web:fd0726e70ce4b83b08beeb",
  measurementId: "G-4GNZK69QMN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


export default function log(eventName, eventParams, option){
    logEvent(analytics, eventName, eventParams, option);
}